import { Column, flexRender, HeaderContext } from "@tanstack/react-table";
import { ChevronDown, ChevronUp, ChevronUpDown } from "../../../atoms/icons";
import { Button, ButtonVariant } from "../../../atoms/Button";
import styles from "../DataTable.module.scss";

interface Props<T> {
  column: Column<T, unknown>;
  context: HeaderContext<T, unknown>;
  children?: React.ReactNode;
}

export function HeaderColumn<T = unknown>({ column, context }: Props<T>) {
  return (
    <div className={styles.header}>
      <span>{flexRender(column.columnDef.header, context)}</span>
      <>
        {column.getCanSort() ? (
          <Button variant={ButtonVariant.PLAIN} onClick={column.getToggleSortingHandler()} type="button">
            {column.getIsSorted() === "asc" && <ChevronUp aria-label="Oplopend" />}
            {column.getIsSorted() === "desc" && <ChevronDown aria-label="Afopend" />}
            {column.getIsSorted() === false && <ChevronUpDown />}
          </Button>
        ) : null}
      </>
    </div>
  );
}
