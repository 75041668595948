import { ColumnDef, flexRender, getCoreRowModel, Row, Table, TableOptions, useReactTable } from "@tanstack/react-table";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "../../atoms/Spinner/Spinner";
import styles from "./DataTable.module.scss";
import { HeaderColumn } from "./header/column";

interface Props<T> {
  className?: string;
  header?: React.FC<{ table: Table<T> }>;
  footer?: React.FC<{ table: Table<T> }>;
  columns: ColumnDef<T, string>[];
  data: T[];
  onRowClick?: (row: T) => void;
  expandedCustomComponent?: React.FC<{ row: Row<T> }>; // when row is expanded, renders this component in table row below expanded row
  testId?: string;
  features?: Omit<TableOptions<T>, "columns" | "data" | "getCoreRowModel">;
  isLoading: boolean;
  isError: boolean;
}

export function DataTable<T>({
  columns,
  header: Header,
  footer: Footer,
  data,
  features,
  className,
  onRowClick,
  expandedCustomComponent: ExpandedCustomComponent,
  testId,
  isLoading,
  isError,
}: Props<T>) {
  const table = useReactTable<T>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    ...features,
    defaultColumn: {
      enableSorting: false,
    },
  });

  const handleRowClick = (row: Row<T>) => (event: React.MouseEvent<HTMLTableRowElement>) => {
    if (onRowClick) {
      onRowClick(row.original);
    }
  };

  const { t } = useTranslation(["common", "errors"]);

  return (
    <>
      {Header && <Header table={table} />}
      <table className={classNames([styles.DataTable, className])} data-testid={testId || ""}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  scope="col"
                  style={{
                    width: header.getSize(),
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <HeaderColumn<T> column={header.column} context={header.getContext()}>
                      -
                    </HeaderColumn>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const isClickable = onRowClick || row.getCanExpand();
            return (
              <React.Fragment key={row.id}>
                <tr
                  key={row.id}
                  onClick={row.getCanExpand() ? row.getToggleExpandedHandler() : handleRowClick(row)}
                  role="button"
                  className={classNames({
                    [styles.DataTable__SubRow]: row.depth > 0,
                    [styles.DataTable__ClickableRow]: isClickable,
                  })}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        style={{
                          width: cell.column.getSize(),
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
                {row.getIsExpanded() && ExpandedCustomComponent && (
                  <tr>
                    <td colSpan={row.getVisibleCells().length}>{<ExpandedCustomComponent row={row} />}</td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>

      {isLoading && (
        <div className={styles.loading}>
          <Spinner />
          {t("loading")}
        </div>
      )}
      {!isLoading && !isError && table.getRowModel().rows?.length === 0 && (
        <div className={styles.loading}>{t("no_matches")}</div>
      )}
      {!isLoading && isError && <div className={styles.loading}>{t("errors:loading_error")}</div>}

      {Footer && <Footer table={table} />}
    </>
  );
}
